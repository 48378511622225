.section-store-locator {
  .store-locator__nav {
    .store-locator__nav-item.first {
      border-right: 0 !important;
      width: 100% !important;
    }
    .store-locator__nav-item.last {
      display: none !important;
    }
  }
}

.section-customer-service {
  .address-form {
    .address-form__registered-container {
      label {
        margin-right: 15px;
      }
    }
  }
  .contact-form {
    .contact-form__sub-question-subtype {
      .sub-question-subtype3,
      .sub-question-subtype2,
      .sub-question-subtype1 {
        display: inline-block;
        margin-right: 0;
      }
    }
  }
}

@media only screen and (orientation: landscape) {
  .section-customer-service {
    .contact-form {
      .contact-form__sub-question-subtype {
        .sub-question-subtype3,
        .sub-question-subtype2,
        .sub-question-subtype1 {
          width: 52%;
        }
      }
    }
  }
}

.page-footer {
  .footer-signup-email {
    #email-signup__form {
      input[type='text'] {
        font-size: 12px;
        letter-spacing: 0.07em;
      }
    }
  }
}

.page-branding {
  &__logo-svg {
    @media #{$device-small-only} {
      width: 170px;
    }
  }
}

.page-wrapper {
  .mpp {
    &__product-grid {
      .product-brief {
        .button__quickshop {
          font-size: 10px;
        }
      }
    }
  }
  .page-main {
    .main-content {
      .foundation-custom-mpp-page {
        .field-mobile-template {
          .hero-tout-mobile {
            .hero-tout-mobile__promo {
              .subhead {
                a {
                  top: 81px !important;
                  left: 0px !important;
                  width: 320px !important;
                  height: 172px !important;
                }
              }
            }
          }
        }
      }
      .foundation-finder-page {
        .field-mobile-template {
          .ff-quiz {
            .ff-quiz__carousel-wrapper {
              .ff-quiz__slide--1 {
                .ff-quiz__question {
                  li {
                    width: 29%;
                  }
                  li:first-child {
                    width: 42%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .utility-nav {
    &__signin-text {
      @media #{$device-small-only} {
        font-size: 10px;
      }
    }
  }
}

.colorbox {
  &__wishlist-confirm {
    #cboxContent {
      height: 190px !important;
    }
  }
}

.ee-tout-wrapper {
  .ee-tout {
    .ee-tout__content {
      top: 57%;
      .subcopy {
        font-size: 11px;
      }
      a.ee-tout__link {
        padding-top: 20px;
      }
      .text-promo__content {
        .text-promo__subcopy {
          padding: 20px 0 0 0;
        }
      }
    }
  }
}

.modern-muse-le-rouge-landing-page,
.modern-muse-elc-mpp {
  .hero-tout-mobile,
  .video_local_mobile {
    .cta__button.button--cta-custom {
      width: 270px;
      background-image: url('/media/images/global/sprites-s38a9885fce.png');
      background-repeat: no-repeat;
      background-size: auto auto;
      background-position: 60px -2837px;
      // background-size: auto auto;
      // background-repeat: no-repeat;
      background-color: $color-white;
      padding: 0 0 0 110px;
      border: 1px solid $color-navy;
      color: $color-navy;
      line-height: 40px;
      overflow: hidden;
      text-align: left;
      cursor: pointer;
      margin: 0 auto;
      height: 40px;
    }
  }
}

.modern-muse-le-rouge-landing-page {
  .hero-tout-mobile {
    .tout-cta-button-wrapper--mobile {
      bottom: -40px;
    }
  }
}

#colorbox {
  #cboxContent {
    #cboxLoadedContent {
      .order_arrival_details {
        .order_delivery_info {
          .header {
            height: 40px;
          }
        }
      }
    }
  }
}

.tiny-waitlist-overlay {
  .field {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    input {
      margin-top: 10px;
    }
  }
  .align-r {
    .button--secondary {
      margin-top: 10px;
    }
  }
}

.colorbox__tiny-waitlist-overlay {
  #cboxLoadedContent {
    width: 100% !important;
    .bisMobileIframe {
      height: 430px !important;
      width: 100% !important;
      .tiny-waitlist-overlay {
        height: 100%;
        overflow-y: inherit;
        padding: 0;
      }
    }
  }
}
/* Add tracking -50 to all class that using new font "Conv_OptimaEL-Text" */
h1,
h2,
h3 {
  letter-spacing: -0.05em;
}

h4 {
  letter-spacing: -0.03em;
}

.headline--page,
.headline--primary,
.headline--large {
  letter-spacing: -0.05em;
}

.headline--secondary,
.headline--medium,
.device-pc .discover-more__header {
  letter-spacing: -0.05em;
}

.headline--tertiary,
.section-store-locator.device-pc .store-locator .store-locator__title {
  letter-spacing: -0.05em;
}

.promo-unit__headline {
  font-family: $optimaregular;
  letter-spacing: -0.08em;
}

.promotions .promo-unit__headline {
  letter-spacing: -0.05em;
}

.headline--products,
.mpp__header {
  letter-spacing: -0.05em;
}

.headline--quaternary,
blockquote,
.pull-quote,
.loyalty_mrkt_ldng .loyalty_multi_image_tout {
  letter-spacing: -0.05em;
}

.anr-simple__header-subhead {
  letter-spacing: -0.05em;
}

.anr-simple__sec2plus-headline {
  letter-spacing: -0.05em;
  font-family: $optimalight !important;
}

.text-primary-headlines {
  letter-spacing: -0.05em;
}

.text-medium-headlines {
  letter-spacing: -0.07em;
}

.text-product-names-main,
.spp-product__header {
  letter-spacing: -0.05em;
}

.text-product-names-sub,
.spp-product__sub-header,
.spp-howtouse__header {
  letter-spacing: -0.05em;
}

.text-batch-2-primary-headline {
  letter-spacing: -0.05em;
}

.text-batch-2-medium-headline {
  letter-spacing: -0.05em;
}

.change-is-coming .cic-content .title1,
.change-is-coming .cic-content .title2 {
  letter-spacing: -0.05em;
}

.device-mobile .change-is-coming .cic-content .title1,
.device-mobile .change-is-coming .cic-content .title2 {
  letter-spacing: -0.1em;
}

.mpp__category-links--content {
  letter-spacing: -0.05em;
}

.chat-parallax__headline {
  letter-spacing: -0.07em;
}

.special-offer-row-promo-wrapper.special-offer-theme-regular .special-offer-headline {
  letter-spacing: -0.05em;
}

.special-offer-row-wrapper.special-offer-theme-regular .special-offer-headline {
  letter-spacing: -0.05em;
}

.special-offer-row-mobile.special-offer-theme-regular .special-offer-headline {
  letter-spacing: -0.05em;
}

.signup-tout-wrapper .signup-tout-content .headline1,
.signup-tout-wrapper .signup-tout-content .headline2 {
  letter-spacing: -0.05em;
}

.device-mobile .signup-tout-wrapper .signup-tout-content .headline1,
.device-mobile .signup-tout-wrapper .signup-tout-content .headline2 {
  letter-spacing: -0.1em;
}

.signup-tout-flexible-wrapper .signup-tout-content .headline1,
.signup-tout-flexible-wrapper .signup-tout-content .headline2 {
  letter-spacing: -0.1em;
}

.device-mobile .signup-tout-flxible-mobile-wrapper .signup-tout-content .headline1,
.device-mobile .signup-tout-flxible-mobile-wrapper .signup-tout-content .headline2 {
  letter-spacing: -0.1em;
}

.loyalty_market .loyalty_market__header {
  letter-spacing: -0.07em;
}

.loyalty_market .loyalty_market__tiers__header {
  letter-spacing: -0.07em;
}

.loyalty_market .loyalty_market__tier__header {
  letter-spacing: -0.07em;
}

.loyalty_market .loyalty_market__points__header {
  letter-spacing: -0.1em;
}

.r3__overlay .r3__overlay-header {
  letter-spacing: -0.05em;
}

.device-mobile #colorbox.welcome15-overlay.with-second-language .email_popover__content > h3,
.device-mobile #colorbox.welcome15-overlay.with-second-language .email_popover__content > h4 {
  letter-spacing: -0.05em;
}

.loyalty_popover__promo-header {
  letter-spacing: -0.07em;
}

.spp-discover-more-section-mobile .discover_more__product .discover_more__product-header {
  letter-spacing: -0.05em;
}

.spp-discover-more-section-mobile .discover_more__product .discover_more__product-sub-header {
  letter-spacing: -0.05em;
}

.wishlist-confirm__message {
  letter-spacing: -0.05em;
}

.spp-product__how-to-use__steps #spp-howtouse-steps-headline {
  letter-spacing: -0.001em;
}

.spp-product__how-to-use__steps .spp__steps .spp_howtouse_content .step-headline {
  letter-spacing: -0.05em;
}

.boutique-tout .boutique-tout-main-headline-wrapper .boutique-tout-main-headline {
  letter-spacing: -0.05em;
}

.boutique-tout.boutique-tout-size-small
  .boutique-tout-main-headline-wrapper
  .boutique-tout-main-headline {
  letter-spacing: -0.07em;
}

.ff-results .ff-results__count {
  letter-spacing: -0.07em;
}

.ff-results .ff-results__regimen-step-gutter {
  letter-spacing: -0.05em;
}

.section-store-locator.device-pc .store-locator .store-locator__title {
  letter-spacing: -0.05em;
}

.section-store-locator.device-pc .store-locator .intl-search .intl-search__label {
  letter-spacing: -0.06em;
}

.ee--subhead {
  letter-spacing: -0.05em;
}

.ee-first-letter {
  letter-spacing: -0.05em;
}

.formatter-estee-edit .header-meta__subhead {
  letter-spacing: -0.05em;
}

.ee-landing__filters__side__wrapper {
  letter-spacing: -0.05em;
}

.formatter-beauty-feed__header > .headline {
  letter-spacing: -0.07em;
}

.formatter-beauty-feed__footer > .headline {
  letter-spacing: -0.07em;
}

.beauty-feed-tout--tool__headline {
  letter-spacing: -0.05em;
}

.beauty-feed-tout--twitter .social-content {
  letter-spacing: -0.07em;
}

.sidebar-chat .sidebar-chat__header {
  letter-spacing: -0.05em;
}

.review-submit__product-head {
  letter-spacing: -0.05em;
}

.checkout-page-title {
  letter-spacing: -0.05em;
}

.samples-page .samples-panel__title {
  letter-spacing: -0.04em;
}

.confirmation-panel h3 {
  letter-spacing: -0.05em;
}

.checkout__header-item-count {
  letter-spacing: -0.05em;
}

#loyalty__panel__benefits .loyalty__panel__benefits__title,
#loyalty__panel__how-to-earn .loyalty__panel__how-to-earn__title,
#loyalty__panel__leave .loyalty__panel__leave__title,
#loyalty__panel__offers .loyalty__panel__offers__title,
#loyalty__panel__points .loyalty__panel__points__title,
#loyalty__panel__transactions .loyalty__panel__transactions__title,
#loyalty__panel__benefits .loyalty__panel__benefits__tier-level,
#loyalty__panel__offers__sku .loyalty__panel__offers__sku__title {
  letter-spacing: -0.04em;
}

#loyalty__content .e-list_not_signed .sub_title {
  letter-spacing: -0.045em;
}

#loyalty__content .points__current {
  letter-spacing: -0.05em;
}

#loyalty__panel__receipt .loyalty__panel__receipt__submit__method__title {
  letter-spacing: -0.07em;
}

.el-search-block input[type='text'].form-text {
  letter-spacing: -0.05em;
}

.el-search-block__links a {
  letter-spacing: -0.06em;
}

.search-page__headline {
  letter-spacing: -0.05em;
}

input[type='text'].search-term {
  letter-spacing: -0.07em;
}

#photo-upload-query div {
  letter-spacing: -0.05em;
}

.beauty-consultations {
  letter-spacing: -0.05em;
}

.up-next__label {
  letter-spacing: -0.05em;
}

.order_arrival_details .title {
  letter-spacing: -0.05em;
}

.ff-quiz__inner .ff-quiz__edit-headline {
  letter-spacing: -0.04em;
}

.ff-quiz__inner .ff-quiz__headline {
  letter-spacing: -0.07em;
}

.ff-quiz__inner .ff-quiz__headline--secondary {
  letter-spacing: -0.07em;
}

.ff-quiz__inner .ff-quiz__coverage-image,
.ff-quiz__inner .ff-quiz__intensity-image {
  letter-spacing: -0.1em;
}

.page-wrapper .spp .product-full h4.product_brief__sub-header {
  letter-spacing: -0.05em;
}

.page-wrapper .ff-quiz__inner .ff-quiz__slide--1 .ff-quiz__headline {
  letter-spacing: -0.05em;
}

.page-wrapper
  .estee-edit-article-fragrance-glossary-page
  .formatter-estee-edit
  .ee_fragrance-dictionary-ex {
  letter-spacing: -0.03em;
}

#prechat_container .prechat-form-container h4 {
  letter-spacing: -0.05em;
}

.anr-simple__sec2plus-headline {
  letter-spacing: -0.1em;
}

.headline--section {
  letter-spacing: -0.035em;
}

.spp-product__details .spp-product__details-header {
  letter-spacing: -0.05em;
}

.order_arrival_details .title {
  letter-spacing: -0.05em;
}

.video_youtube.fonts-theme-regular header .video-headline-mobile,
.video_youtube_double.fonts-theme-regular header .video-headline-mobile {
  letter-spacing: -0.05em;
}

.video_youtube_custom_mobile.fonts-theme-regular header .video-headline-mobile {
  letter-spacing: -0.05em;
}

.video_youtube_custom_mobile.v2 .video_youtube__content .video-title span {
  letter-spacing: -0.05em;
}

.ff-quiz__headline--secondary {
  letter-spacing: -0.05em;
}

.ff-quiz__coverage-image,
.ff-quiz__intensity-image {
  letter-spacing: -0.05em;
}

.section-store-locator.device-mobile .store-locator .store-locator__title {
  letter-spacing: -0.05em;
}

.section-store-locator.device-mobile .store-locator .intl-search .intl-search__label {
  letter-spacing: -0.06em;
}

.device-mobile .header__headline.headline--small,
.device-mobile .header__headline.headline--small2,
.device-mobile .header__headline.headline--small3 {
  letter-spacing: -0.07em;
}

@media only screen and (min-width: 737px) {
  .headline--section {
    .headline--products,
    .mpp__header {
      letter-spacing: -0.07em;
    }
  }
  .header__headline.headline--small,
  .header__headline.headline--small2,
  .header__headline.headline--small3 {
    letter-spacing: -0.07em;
  }
  .sign-in-component .sign-in-component__header {
    letter-spacing: -0.04em;
  }
  .sign-in-component .sign-in-component__confirm--registration .sign-in-component__header {
    letter-spacing: -0.05em;
  }
  .ee-landing__filters {
    letter-spacing: -0.05em;
  }
  .ee-landing__filters .mobile-trigger {
    letter-spacing: -0.05em;
  }
  .ee-landing__filters li {
    letter-spacing: -0.05em;
  }
  .need-help-panel .need-help-panel__title {
    letter-spacing: -0.05em;
  }
  #loyalty__panel__offers .loyalty__panel__offers__title {
    letter-spacing: -0.04em;
  }
  #loyalty__content .e-list_not_signed .title_main {
    letter-spacing: -0.05em;
  }
  .loyalty-account-page #loyalty__page__history .loyalty__panel__transactions__title {
    letter-spacing: -0.04em;
  }
}

.device-mobile {
  #colorbox.welcome15-overlay {
    #cboxContent {
      #cboxClose {
        text-indent: -9999em;
      }
    }
  }
}

.payment-panel {
  .payment-edit-content {
    .form-container {
      .payment-type {
        overflow: inherit;
      }
    }
    .security-info a {
      width: 100%;
    }
  }
}

.lookup_results_container {
  .address_lookup_wrapper {
    border: 1px solid $color-lighter-gray-alt;
    clear: both;
    padding: 10px;
    width: 100%;
    height: 200px;
    overflow: auto;
    margin-bottom: 15px;
    li {
      padding-bottom: 1em;
    }
  }
}
//Product Foundation VTO
.product-foundation-vto {
  &__mobile-ctrl {
    .container {
      .vto-info {
        .product-full {
          &__add-button {
            font-size: 10px;
            padding: 0 12px;
          }
        }
        .product-sub-heading {
          font-size: 11px;
        }
      }
    }
  }
  &__container {
    ul.shade-list-vto {
      li {
        .vto-filter-text {
          font-size: 9px;
          position: absolute;
          #{$ldirection}: 50%;
          @include transform(translateX(-50%));
        }
      }
    }
  }
  &__overlay-check {
    #vto-disclaimer-checkbox ~ label::before {
      border: 1px solid $color-black;
    }
  }
}

.vto-skin-care-formatter {
  .vto-skin-care-screens {
    &__tips-container {
      li {
        font-size: 18px;
      }
    }
    &__tips-title {
      font-size: 26px;
      line-height: 1.07;
    }
  }
  .elc-vto-skin-care {
    .elc-skin-care {
      &-cta,
      &-result-cta {
        font-size: 11px;
      }
    }
  }
}

iframe.bisMobileIframe {
  height: 500px;
}

.embeddedServiceHelpButton {
  .helpButton {
    display: none;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $optimalight;
}

.loading {
  @include background(image-url('ajax-loading.gif') no-repeat center center);
  background-color: $extended_mpp_black_pearl;
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.25;
}
