#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-review {
        .pr-rd-footer {
          .pr-helpful {
            &-btn {
              border: 0;
              text-decoration: underline;
            }
            &-yes {
              .pr-helpful-count {
                &:before {
                  content: 'Sí  (';
                  visibility: visible;
                }
                &:after {
                  content: ' )';
                  visibility: visible;
                }
              }
            }
            &-no {
              .pr-helpful-count {
                &:before {
                  content: 'No  (';
                  visibility: visible;
                }
                &:after {
                  content: ' )';
                  visibility: visible;
                }
              }
            }
          }
        }
      }
      .pr-rd-main-header {
        .pr-multiselect[data-reactid-powerreviews$='stuedad'] {
          &::before {
            content: 'FILTRAR:';
            font-size: 10px;
          }
        }
        .pr-rd-review-header-contents {
          .pr-multiselect-button {
            span {
              font-size: 10px;
            }
          }
        }
        .pr-rd-review-header-sorts {
          .pr-rd-sort-group {
            &::before {
              font-size: 10px;
              content: 'ORDENAR: ';
              top: 14px;
            }
            .pr-rd-sort {
              font-size: 9px;
              top: 2px;
            }
          }
        }
        .pr-rd-search-container {
          .pr-rd-search-reviews-icon-button {
            width: 60px;
            .pr-search-icon {
              &::after {
                content: 'BUSCAR';
              }
            }
          }
          .pr-rd-search-reviews-input {
            padding-right: 70px;
          }
        }
      }
    }
    .pr-review-snapshot {
      .pr-review-snapshot-snippets {
        .pr-snippet-stars-reco-stars {
          .pr-snippet-rating-decimal {
            &:after {
              content: ' de 5';
            }
          }
        }
      }
    }
  }
}

.write_a_review__container {
  #pr-write {
    .p-w-r {
      .form-group[data-reactid-powerreviews$='ag139413_msq74290'],
      .form-group[data-reactid-powerreviews$='ag140769_msq75317'] {
        display: none;
      }
    }
  }
}
