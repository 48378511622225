.clear {
  clear: both;
  zoom: 1;
}

.cright {
  clear: right;
}

.cleft {
  clear: left;
}

//Sticky Checkout
.page-wrapper-checkout {
  padding-bottom: 100px;
  .page-footer {
    display: none;
  }
  .checkout-footer {
    display: none;
  }
  .checkout {
    overflow: visible;
    .viewcart-buttons {
      display: none;
    }
    .sticky-checkout-button {
      border-top: 1px solid $color-black;
      background: $color-white;
      z-index: 1002;
      height: auto;
      padding: 10px 15px;
      @media #{$large-up} {
        display: none;
      }
      a {
        height: 50px;
        line-height: 50px;
        vertical-align: middle;
        margin: 10px 0;
        padding: 0;
        border: 0;
      }
    }
    .hide-sticky-checkout-button {
      display: none;
    }
    .continue-button-wrapper {
      @include box-shadow(0px -3px 6px $color-light-gray);
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      text-align: center;
      a.btn,
      input.btn {
        display: block;
        width: 100%;
        background-color: $color-navy;
        color: $color-white;
        font-weight: normal;
        @media #{$large-up} {
          font-weight: bold;
        }
      }

      ///
      /// Undo Sticky on Desktop
      ///
      @media #{$large-up} {
        position: static;
        box-shadow: none;
        width: 50%;
        height: auto;
        float: right;
        padding: $checkout-spacing--large $checkout-spacing--small $checkout-spacing--large 0;
      }
    }
  }
}
