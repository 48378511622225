.enhanced-cart-page {
  margin-top: 0;
  @media #{$medium-up} {
    margin-top: 0;
  }
  .viewcart-buttons {
    border-bottom: 1px solid $color-light-gray;
    .payments-icon {
      .oxxo-cash {
        background: url('/media/images/checkout/cash_oxxo.png') no-repeat;
      }
      .seveneleven-cash {
        background: url('/media/images/checkout/cash_seveneleven.png') no-repeat;
      }
      .paypal {
        @include swap_direction(margin, 0 10px 5px 8px);
      }
      .oxxo-cash,
      .seveneleven-cash {
        background-position: center;
        background-size: 100%;
        height: 16px;
        margin: 0 7px;
        max-width: 32px;
        width: 24px;
        @media #{$large-only} {
          margin: 0 4px;
        }
      }
      .paypal__checkout--image {
        height: 16px;
        padding-top: 0;
        margin-top: 5px;
      }
      .kueski {
        background: no-repeat center/100% url('/media/images/checkout/kueskipay.svg');
        width: 60px;
        height: 16px;
      }
    }
  }
  .checkout {
    @include clearfix;
    position: static;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    max-width: $checkout-width;
    font-size: $checkout-font-size--base;
    letter-spacing: initial;
    @media #{$large-up} {
      position: relative;
      padding-bottom: 150px;
    }
    .checkout__content {
      .cart-header {
        display: none;
      }
      .signin-to-see-cart {
        font-size: 15px;
      }
      .cart-item {
        &__remove-form {
          position: absolute;
          margin: 0;
          .remove_link {
            text-indent: -9999px;
            background-size: contain;
            display: block;
          }
        }
        &__thumb {
          padding: 0;
          float: left;
        }
        &__total {
          float: right;
          position: static;
          @media #{$medium-up} {
            top: 20px;
          }
        }
        &__total.percent-discount {
          @media #{$medium-up} {
            position: static;
            top: 20px;
          }
          position: absolute;
          top: 22px;
        }
        &__total.sale-price.percent-discount {
          top: 48px;
          @media #{$medium-up} {
            position: absolute;
            top: 23px;
          }
        }
        &__total.sale-price {
          position: absolute;
          top: 24px;
          @media #{$medium-up} {
            position: static;
          }
        }
        &__price {
          width: auto;
          .unit_price {
            @media #{$medium-up} {
              float: right;
            }
          }
        }
        &__desc {
          font-family: $optimaregular;
          font-size: 12px;
          letter-spacing: initial;
        }
        &__price-content.sale-price {
          position: relative;
          margin-bottom: 20px;
          .strike_price {
            @media #{$medium-up} {
              float: left;
            }
          }
        }
        &__price-content.percent-discount {
          position: relative;
          .strike_price {
            @media #{$medium-up} {
              float: initial;
            }
          }
        }
      }
    }
    .panel.order-summary {
      margin-bottom: 0;
      .order-summary__content {
        @include clearfix;
        font-size: 14px;
        letter-spacing: 0.1em;
        .order-summary__shipping {
          @media #{$medium-up} {
            margin-bottom: 16px;
          }
        }
        .order-summary__total-label {
          background-color: $color-lighter-gray;
          margin: 0;
        }
        .total-label__wrapper {
          padding: 0;
        }
        .order-summary__total-value {
          background-color: $color-lighter-gray;
          line-height: 18px;
        }
        .total-value__wrapper {
          padding: 0;
          border-top: 0;
        }
        .kueski-widget {
          padding: 10px 16px;
          visibility: hidden;
        }
      }
      .discount.label,
      .discount.value {
        margin-bottom: $checkout-spacing--medium;
      }
      .discount.label {
        padding-left: $checkout-spacing--extra-large;
        width: 70%;
      }
      .discount.value {
        padding-right: $checkout-spacing--extra-large;
        width: 30%;
        font-family: $akzidenz;
        font-size: $checkout-font-size--base;
        font-weight: bold;
        letter-spacing: 0.05em;
        line-height: $checkout-spacing--large;
      }
      .label {
        float: left;
        text-align: left;
        text-transform: uppercase;
        line-height: 18px;
        margin: 0 0 6px;
      }
      .value {
        float: right;
        margin: 0;
        text-align: right;
      }
    }
    .form-item {
      select {
        margin-top: $checkout-spacing--extra-small;
        width: 100%;
        height: calc(4em + 2px);
        border: $checkout-line--input;
        border-radius: 0;
        color: $color-navy;
        @include box-shadow(none);
        &:disabled {
          background-color: $color-lighter-gray;
        }
        @media #{$large-up} {
          height: calc(3.5em + 2px);
          border: $checkout-line--input-light-gray;
        }
      }
      &.ship-method {
        margin-bottom: 0;
      }
    }
    .panel--offer-code {
      .offer-code__form {
        form {
          font-size: 12px;
          letter-spacing: initial;
        }
      }
    }
    .column.left {
      padding: 0;
      border: none;
      background-color: transparent;
      font-size: 15px;
      .single-message {
        margin-top: 0;
        margin-bottom: $checkout-spacing--large;
        text-align: center;
        padding: 0 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .messages {
        padding: 0 24px;
        margin: 0 0 $checkout-spacing--large;
        @media #{$large-up} {
          margin: 0;
          padding: 0;
          text-align: center;
          font-family: $optimaregular;
          font-size: 22px;
        }
      }
    }
    #checkout-sidebar .top-viewcart-buttons {
      display: block;
      margin: 0;
    }
    .form-container {
      @include clearfix;
    }
    ///
    /// Adaptive Placeholder Adjustments
    ///
    .adpl {
      input[type='text'] {
        @include adaptive-placeholder($checkout-adaptive-placeholder-parameters);
        border: $checkout-line--input;
        & + label {
          @media #{$large-up} {
            line-height: 4.4;
          }
        }
      }
    }
  }
  .pg_wrapper.checkout {
    @media #{$large-up} {
      padding: 10px 0 150px;
    }
  }
  .checkout__sidebar#checkout-sidebar {
    .viewcart-buttons.is-mobile {
      .viewcart-buttons-panel {
        border-top: 0;
      }
    }
  }
  .recommended-section {
    display: none;
    @media #{$large-up} {
      display: block;
      clear: both;
      padding: $checkout-spacing--large * 2 0 $checkout-spacing--large 0;
      margin-top: 0;
    }
    .recommended-products-panel__title {
      border-bottom: none;
    }
    .recommended-products__list {
      .sku-brief-editorial__image {
        width: 100%;
        max-width: 144px;
        margin: auto;
        height: auto;
      }
    }
  }
  .viewcart-empty {
    .panel--offer-code {
      display: none;
    }
    .messages {
      text-align: center;
      width: 100%;
    }
    .js-cart-items.products {
      border: 0;
      margin: 0;
      padding: 0;
    }
    .shop-best-seller,
    .go-to-shopping {
      margin-bottom: 20px !important;
    }
  }
  .cart-item__price-content .sale-price.percent-discount {
    @media #{$medium-up} {
      position: relative;
    }
    .cart-item__price {
      padding-bottom: 40px;
      @media #{$medium-up} {
        padding: 0;
      }
    }
  }
}
